<template>
    <Modal
        class="wrap"
        v-model="modal"
        footer-hide
        :width="1200"
        :mask-closable="false"
        @on-visible-change="handleVisibleChange">
        <template #header>
            <p class="custom-title">
                <span class="text">已投屏幕（{{total}}）</span>
                <span v-if="total !== 0" class="cancel-all pointer" @click="handleDel('all')">全部取消投放</span>
            </p>
        </template>
        <no-data v-show="total === 0" text="暂无投放"></no-data>
        <div v-show="total > 0" class="content-wrap">
            <div class="content">
                <div
                    v-for="item in list"
                    :key="item.rowid"
                    class="content-item">
                    <div class="left-section">
                        <div :class="['img-wrap', item.dir==='vertical'?'img-wrap-vertical':'']">
                            <img class="image-center" :src="`/cym/${item.cover_uri}`">
                            <div class="device-status">
                                <Badge :status="item.state === 'online' ? 'success' : 'error'" />
                                <span class="text">{{ item.state_text._ }}</span>
                            </div>
                        </div>
                        <span class="category">
                            <span class="category-text">{{ item.category_text._ }}</span>
                        </span>
                    </div>
                    <div class="right-section">
                        <div class="info">
                            <div class="device-name line-overflow">{{ item.name }}</div>
                            <div v-if="item.department_name && is_org" class="device-site line-overflow">{{ item.department_name }}</div>
                            <div class="date">
                                <Icon type="ios-calendar-outline" size="16" />
                                <span v-if="item.plan.date.switch">长期投放</span><span v-else>{{ item.plan.date.start }}&nbsp;-&nbsp;{{ item.plan.date.end }} <span v-if="item.dateLabel" class="label">{{ item.dateLabel }}</span></span>
                            </div>
                            <div class="week">
                                <Icon type="ios-refresh" size="16" />
                                <span v-if="item.plan.week.switch">每天重复</span><span v-else>{{ item.plan.week.text }}</span>
                            </div>
                            <div class="time">
                                <Icon type="ios-time-outline" size="16" />
                                <span v-if="item.plan.time.switch">全天播放</span><span v-else>{{ item.plan.time.start }}&nbsp;-&nbsp;{{ item.plan.time.end }}&nbsp;<span v-if="item.timeLabel" class="label">{{ item.timeLabel }}</span></span>
                            </div>
                            <div class="others">
                                <span v-if="item.has_more_plan" class="more pointer" @click="handlePlayPlan(item)">更多计划</span>
                                <span class="cancel pointer" @click="handleDel(null, item.rowid)">取消投放</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Spin fix size="large" v-if="loading"></Spin>
            <Page
                class="page"
                :total="total"
                :current="current"
                :page-size="pageSize"
                show-elevator
                @on-change="nextPage" />
        </div>
        <play-plan-modal
            :show="playPlanModalShow"
            :deviceInfo="deviceInfo"
            @cancel="handlePlayPlanModalCancel"></play-plan-modal>
    </Modal>  
</template>
<script>
    import noData from '@/views/components/noData'
    import playPlanModal from '@/views/screens/mediaScreens/components/playPlan'


    export default {
        name: "",
        mixins: [],
        components: {
            noData,
            playPlanModal
        },
        props: {
            show: {
                default: false
            },
            programRowid: {
                default: null
            },
            is_org: {
                default: false
            }
        },
        data () {
            return {
                modal: false,
                loading: true,
                total: 0,
                current: 1,
                pageSize: 9,
                list: [],
                weeksList: [
                    {
                        value: 0,
                        text: '周一'
                    },{
                        value: 1,
                        text: '周二'
                    },{
                        value: 2,
                        text: '周三'
                    },{
                        value: 3,
                        text: '周四'
                    },{
                        value: 4,
                        text: '周五'
                    },{
                        value: 5,
                        text: '周六'
                    },{
                        value: 6,
                        text: '周天'
                    }
                ],
                deviceInfo: {},
                playPlanModalShow: false
            }
        },
        computed: {},
        watch: {
            show(val) {
                this.modal = val
                if (val) {
                    this.getData()
                }
            }
        },
        methods: {
            handleVisibleChange(e) {
                if(!e) {
                    this.cancel()
                }
            },
            cancel() {
                this.$emit('close')
                this.list = []
                this.current = 1
            },
            submit() {

            },
            nextPage(e) {
                this.current = e
                this.getData()
            },
            getData() {
                let params = {
                    start: (this.current - 1) * this.pageSize,
                    count: this.pageSize
                }
                this.loading = true
                this.$api.get(`programs/${this.programRowid}/binding_terminals`, { params }).then(({ data, total_count }) => {
                    for (let k = 0, len = data.length; k < len; k++) {
                        let values = data[k].plan.week.weeks,
                            len1 = values.length,
                            len2 = this.weeksList.length,
                            str = '';
                        for (let i = 0; i < len1; i++) {
                            for (let j = 0; j < len2; j++) {
                                if (values[i] == this.weeksList[j].value) {
                                    str += this.weeksList[j].text
                                    break;
                                }
                            }
                            if (i !== len1 - 1) {
                                str += '、'
                            }
                        }
                        data[k].plan.week.text = str
                        let regTime = /^(\d+):(\d+)/,
                            resultTimeStart = data[k].plan.time.start.match(regTime),
                            resultTimeEnd = data[k].plan.time.end.match(regTime),
                            regDate = /(\d+)-(\d+)$/,
                            resultDateStart = data[k].plan.date.start.match(regDate),
                            resultDateEnd = data[k].plan.date.end.match(regDate);
                        if (!data[k].plan.time.switch) {
                            try {
                                if (Number(resultTimeStart[1]) > Number(resultTimeEnd[1]) || (Number(resultTimeStart[1]) === Number(resultTimeEnd[1]) && Number(resultTimeStart[2]) > Number(resultTimeEnd[2]))) {
                                    data[k].timeLabel = '跨天'
                                }
                            } catch {
                                console.log('非法数据，跨天标签处理失败')
                            }
                        }
                        if (!data[k].plan.date.switch) {
                            try {
                                if (Number(resultDateStart[1] > Number(resultDateEnd[1]) || (Number(resultDateStart[1]) === Number(resultDateEnd[1])) && Number(resultDateStart[2]) > Number(resultDateEnd[2]))) {
                                    data[k].dateLabel = '跨年'
                                }

                            } catch {
                                console.log('非法数据，跨年标签处理失败')
                            }
                        }
                    }
                    this.list = data
                    this.total = total_count
                }).catch(() => {}).finally(() => {
                    this.loading = false
                })
            },
            handleDel(from, rowid) {
                let text = '是否取消投放'
                if (from === 'all') text = '是否全部取消投放'
                this.$Modal.confirm({
                    title: '提示',
                    content: text,
                    okText: '确定',
                    cancelText: '取消',
                    loading: true,
                    closable: true,
                    onOk: () => {
                        if (from === 'all') {
                            this.$api.delete(`programs/${this.programRowid}/binding_terminals`).then(() => {
                                this.getData()
                            }).catch(() => {}).finally(() => {
                                this.$Modal.remove()
                            })
                        } else {
                            this.$api.delete(`terminals/${rowid}/plans/programs?program_rowid=${this.programRowid}`).then(() => {
                                this.getData()
                            }).catch(() => {}).finally(() => {
                                this.$Modal.remove()
                            })
                        }
                    }
                })
            },
            handlePlayPlan(item) {
                this.playPlanModalShow = true
                this.deviceInfo = item
            },
            handlePlayPlanModalCancel() {
                this.playPlanModalShow = false
            },
        },
        created() {}
    }
</script>
<style scoped lang="less">
@import "~less/color.less";
.custom-title {
    height: 35px;
    .text {
        vertical-align: middle;
    }
    .cancel-all {
        display: inline-block;
        margin-left: 10px;
        width: 135px;
        height: 35px;
        background: @primary-color;
        border-radius: 4px;
        line-height: 35px;
        font-size: 14px;
        color: #fff;
        text-align: center;
    }
}
.content-wrap {
    overflow-x: hidden;
    position: relative;
    .content {
        width: 1176px;
        .content-item {
            display: inline-block;
            vertical-align: top;
            margin: 0 5px 14px 0;
            width: 386px;
            height: 206px;
            border: 1px solid #e8eaec;
            .left-section {
                position: relative;
                display: inline-block;
                width: 169px;
                height: 100%;
                background: #f6f7f9;
                .category {
                    position: absolute;
                    left: 12%;
                    bottom: 4%;
                    height: 16px;
                    padding: 0 2px;
                    border-radius: 8px;
                    background: @primary-color;
                    color: #ffff;
                    font-size: 10px;
                    text-align: center;
                    line-height: 16px;
                    white-space:nowrap;
                    .category-text {
                        display: block;
                        transform: scale(0.8);
                    }
                }
                .img-wrap {
                    position: absolute;
                    top: 31%;
                    bottom: 31%;
                    left: 10%;
                    right: 10%;
                    .device-status {
                        position: absolute;
                        left: 5%;
                        bottom: 12%;
                        width: 40px;
                        height: 16px;
                        padding-left: 5px;
                        border-radius: 4px;
                        background: #000;
                        color: #fff;
                        line-height: 16px;
                        .ivu-badge-status {
                            width: 10px;
                            height: 100%;
                        }
                        .text {
                            position: absolute;
                            left: 12px;
                            top: 50%;
                            transform: translateY(-50%) scale(0.8);
                            font-size: 10px;
                            display: block;
                        }
                    }
                }
                .img-wrap-vertical {
                    position: absolute;
                    top: 15%;
                    bottom: 19%;
                    left: 25%;
                    right: 25%;
                    .category {
                        left: 0%;
                        top: 7%;
                    }
                    .device-status {
                        position: absolute;
                        left: 8%;
                        bottom: 7%;
                    }
                }
            }
            .right-section {
                position: relative;
                display: inline-block;
                vertical-align: top;
                padding: 50px 12px 0 22px;
                width: 215px;
                height: 100%;
                color: #7f8792;
                font-size: 12px;
                .info {
                    .device-name {
                        position: relative;
                        width: 100%;
                        height: 33px;
                        font-weight: 700;
                        font-size: 15px;
                        color: #000;
                    }
                    .device-site {
                        margin-bottom: 5px;
                    }
                    .date, .week, .time {
                        & > span {
                            padding-left: 5px;
                        }
                    }
                    .others {
                        position: relative;
                        width: 100%;
                        height: 35px;
                        .more {
                            position: absolute;
                            left: 0;
                            top: 50%;
                            transform: translateY(-50%);
                            color: @primary-color;
                        }
                        .cancel {
                            float: right;
                            width: 90px;
                            height: 35px;
                            border-radius: 4px;
                            background: @primary-color;
                            text-align: center;
                            font-size: 14px;
                            line-height: 35px;
                            color: #fff;
                        }
                    }
                }
            }

        }
    }
}
</style>